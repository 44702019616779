import React from 'react';
import both from '@holmanfm/lib/fp/both';
import Features from '@holmanfm/lib/lib-global';
import {
  hasFeature,
  isNotOrgAdmin,
  doesNotHaveFeature,
  hasAssetBasedService,
} from '@holmanfm/lib/util/routes';
import either from '@holmanfm/lib/fp/either';
import anyPass from '@holmanfm/lib/fp/anyPass';
import Placeholder from '~/shared/components/placeholder';
import NotFound from '~/shared/components/routes/not-found';
import ThemeExample from '../ThemeExample';
import {
  generateNavigationRoutesWithSpecs,
  generateRoutingWithSpecs,
} from './route.utilities';
import lazyLoadComponent from '~/shared/component-loader';
import ResumeEstimator from '~/areas/dealer-portal/payment-estimator/resumed-estimator';

const AssetSelector = lazyLoadComponent(() =>
  import('~/areas/individual-assets/asset-selector')
);

const OrgSetupSinglePage = lazyLoadComponent(() =>
  import('~/areas/org-setup-wizard/single-page/org-setup-single-page')
);

// const SidebarOrgInfo = lazyLoadComponent(() =>
//   import('~/areas/organization-info-sidebar/organization-information-sidebar')
// );

const OrganizationPage = lazyLoadComponent(() =>
  import('~/areas/organization-info-sidebar/organization-page')
);
// const BrowseLossReports = lazyLoadComponent(() =>
//   import('~/areas/loss-reporting/browse-loss-reports')
// );
// const CreateLossReport = lazyLoadComponent(() =>
//   import('~/areas/loss-reporting/create-loss-report')
// );
// const UpdateLossReport = lazyLoadComponent(() =>
//   import('~/areas/loss-reporting/update-loss-report')
// );

// const PMPreferences = lazyLoadComponent(() => import('~/areas/pm-preferences'));

const AssetAddAssetSelector = lazyLoadComponent(() =>
  import('~/areas/assets/asset-add-asset-selector')
);

const ContactUs = lazyLoadComponent(() => import('~/areas/contact-us'));
const DMCA = lazyLoadComponent(() => import('~/areas/dmca'));
const DriverTrainingAssignments = lazyLoadComponent(() =>
  import('~/areas/driver-training-assignments')
);
const Home = lazyLoadComponent(() => import('~/areas/home/home'));
const ShopVehiclesContainer = lazyLoadComponent(() =>
  import('~/areas/marketplace/shop-vehicles/shop-vehicles-container')
);
const MyAccount = lazyLoadComponent(() =>
  import('~/areas/my-account/01-my-account')
);
const BrowsePaymentHistory = lazyLoadComponent(() =>
  import('~/areas/payment-history/browse-payment-history')
);
const PaymentOptions = lazyLoadComponent(() =>
  import('~/areas/payments-options')
);
const PrivacyPolicy = lazyLoadComponent(() => import('~/areas/privacy-policy'));
const RolesCrud = lazyLoadComponent(() => import('~/areas/roles/roles-crud'));
const TelematicsTermsAndConditions = lazyLoadComponent(() =>
  import('~/areas/telematics-terms')
);
const ManageRoles = lazyLoadComponent(() =>
  import('~/areas/roles/v2/manage-roles')
);
const CreditDecision = lazyLoadComponent(() =>
  import('~/areas/credit/browse-credit-decision')
);
const ManageOrders = lazyLoadComponent(() =>
  import('~/areas/manage-orders/browse-manage-orders')
);
const BrowseTelematics = lazyLoadComponent(() =>
  import('~/areas/telematics/browse-telematics')
);
const StockLanding = lazyLoadComponent(() =>
  import('~/areas/vehicle-acquisition/stock/stock-landing/stock-landing')
);
const BrowseStock = lazyLoadComponent(() =>
  import('~/areas/vehicle-acquisition/stock/browse-stock')
);
const StockSearchDetail = lazyLoadComponent(() =>
  import('~/areas/vehicle-acquisition/stock/stock-vehicle-detail/stock-vehicle')
);
const RequestQuote = lazyLoadComponent(() =>
  import('~/areas/vehicle-acquisition/request-quote')
);
const TermsOfUse = lazyLoadComponent(() => import('~/areas/terms-of-use'));
const BrowseTrainingAssignment = lazyLoadComponent(() =>
  import('~/areas/training-assignment/browse-training-assignment')
);
const BrowseFleetAssets = lazyLoadComponent(() =>
  import('~/areas/fleet-assets/browse-fleet-asset')
);
const TrainingSignupManagement = lazyLoadComponent(() =>
  import('~/areas/training-signup-management/training-signup-management')
);
const DriverTrainingContainer = lazyLoadComponent(() =>
  import('~/areas/training/driver-training-container')
);
const UsersManagement = lazyLoadComponent(() =>
  import('~/areas/users-management')
);
const SupplierServices = lazyLoadComponent(() =>
  import('~/areas/supplier-services/supplier-services')
);
const DealerModelAdmin = lazyLoadComponent(() =>
  import('~/areas/dealer-model-admin/dealer-model-admin')
);
const BrowseMaintenanceRepairHistory = lazyLoadComponent(() =>
  import('~/areas/maintenance-repair-history')
);
const MRPreferences = lazyLoadComponent(() => import('~/areas/mr-preferences'));
const VendorLocator = lazyLoadComponent(() =>
  import('~/areas/vendor-locator/vendor-locator')
);
const BrowsePmNotification = lazyLoadComponent(() =>
  import('~/areas/pm-tracker')
);
const BrowseServiceRequests = lazyLoadComponent(() =>
  import('~/areas/service-requests/browse-service-requests')
);
const ViewUpdateRequest = lazyLoadComponent(() =>
  import('~/areas/service-requests/view-update-request')
);
const FuelCardPreferences = lazyLoadComponent(() =>
  import('~/areas/fuel-card/fuel-card-preferences')
);
const FuelCardReplacement = lazyLoadComponent(() =>
  import('~/areas/fuel-card/fuel-card-replacement')
);
const FuelCardTransactions = lazyLoadComponent(() =>
  import('~/areas/fuel/transactions/browse-fuel-transactions')
);
const ManageFuelCards = lazyLoadComponent(() =>
  import('~/areas/fuel/manage-cards/browse-fuel-cards')
);
const FuelAssetTransactions = lazyLoadComponent(() =>
  import('~/areas/fuel/asset-transactions/browse-fuel-asset-transactions')
);
const FuelManagementPreferences = lazyLoadComponent(() =>
  import('~/areas/fuel/management-preferences/management-preferences')
);
const FuelNewAssetGroup = lazyLoadComponent(() =>
  import('~/areas/fuel/management-preferences/asset-groups/new-asset-group')
);
const NewFuelProfile = lazyLoadComponent(() =>
  import(
    '~/areas/fuel/management-preferences/fuel-card-profiles/new-fuel-card-profile'
  )
);
const FuelPinTransactions = lazyLoadComponent(() =>
  import('~/areas/fuel/pin-transactions/browse-pin-transactions')
);

const AssetCTAs = lazyLoadComponent(() =>
  import('~/areas/assets-cta/browse-asset-ctas')
);
const ConsolidatedBilling = lazyLoadComponent(() =>
  import('~/areas/consolidated-billing/browse-consolidated-billing')
);
const ConsolidatedBillingIndex = lazyLoadComponent(() =>
  import('~/areas/consolidated-billing/index')
);
const PricingUpdates = lazyLoadComponent(() =>
  import('~/areas/pricing-updates/pricing-updates')
);
const SelectorsList = lazyLoadComponent(() =>
  import(
    '~/areas/vehicle-acquisition/selectors/list-page/browse-selectors-list'
  )
);
const SingleSelector = lazyLoadComponent(() =>
  import(
    '~/areas/vehicle-acquisition/selectors/single-selector/single-selector'
  )
);
const OrgLocationHierarchy = lazyLoadComponent(() =>
  import('~/areas/locations-hiearchy/org-location-hierarchy')
);
const ComponentsPage = lazyLoadComponent(() =>
  import('~/areas/components-page/components-page')
);

const BuyDirectQuoteRequest = lazyLoadComponent(() =>
  import(
    '~/areas/remarketing/quote-request/buy-direct/buy-direct-quote-request'
  )
);
const PLBQuoteRequest = lazyLoadComponent(() =>
  import('~/areas/remarketing/quote-request/plb/plb-quote-request')
);
const RemarketingRequests = lazyLoadComponent(() =>
  import('~/areas/remarketing/requests/browse-remarketing-requests')
);
const BuyDirectRequestReceipt = lazyLoadComponent(() =>
  import('~/areas/remarketing/requests/buydirect-request-receipt')
);
const PLBRequestReceipt = lazyLoadComponent(() =>
  import('~/areas/remarketing/requests/plb-request-receipt')
);
const PendingRequests = lazyLoadComponent(() =>
  import('~/areas/remarketing/requests/pending-requests')
);
const BuyDirectStatus = lazyLoadComponent(() =>
  import('~/areas/remarketing/status/buy-direct-status')
);
const PLBStatus = lazyLoadComponent(() =>
  import('~/areas/remarketing/status/plb-status')
);

const ParentListOrders = lazyLoadComponent(() =>
  import('~/areas/parent-org/list-orders')
);
const ParentConsolidatedBilling = lazyLoadComponent(() =>
  import('~/areas/parent-org/consolidated-billing')
);

const ParentAssets = lazyLoadComponent(() =>
  import('~/areas/parent-org/asset/browse-asset')
);

const ParentFuelCards = lazyLoadComponent(() =>
  import('~/areas/parent-org/fuel/cards/browse-fuel-cards')
);

const ParentFuelCardsTransactions = lazyLoadComponent(() =>
  import('~/areas/parent-org/fuel/card-transactions/browse-fuel-transactions')
);

const ParentFuelTransactions = lazyLoadComponent(() =>
  import('~/areas/parent-org/fuel/transactions/browse-fuel-transactions')
);

const ParentMaintRepairHistory = lazyLoadComponent(() =>
  import('~/areas/parent-org/maintenance-repair/browse-maint-repair')
);

const ParentRequests = lazyLoadComponent(() =>
  import('~/areas/parent-org/service-requests')
);

const MyGeotabRedirect = lazyLoadComponent(() =>
  import(`~/areas/telematics-ordering/mygeotab-redirect`)
);

const HardwareOrdering = lazyLoadComponent(() =>
  import('~/areas/telematics-ordering/hardware-ordering/ordering')
);

const BrowseHardwareOrders = lazyLoadComponent(() =>
  import('~/areas/telematics-ordering/view-order/browse-view-order')
);

const HardwareStatus = lazyLoadComponent(() =>
  import('~/areas/telematics-ordering/order-status/order-status')
);

const AvailableServices = lazyLoadComponent(() =>
  import('~/areas/services/services')
);

const ParentOrgManagement = lazyLoadComponent(() =>
  import('../parent-org/management')
);

const DealerNextSteps = lazyLoadComponent(() =>
  import('~/areas/org-setup-wizard/dealer/next-steps')
);

const PaymentEstimator = lazyLoadComponent(() =>
  import('../dealer-portal/payment-estimator/payment-estimator')
);

const PaymentEstimatorQuoteRequest = lazyLoadComponent(() =>
  import('../dealer-portal/quote-request/quote-request')
);

const LocationOverview = lazyLoadComponent(() =>
  import('~/areas/location-overview/location-overview')
);

/**
 *
 * const ExampleRoute = {
 *
 *   `id` is used to identify the route and for translation purposes. It must be unique.
 *   id: 'example-route',
 *
 *   `filter` is an option function which can be used to exclude a route or navigation from being
 *    rendered. If undefined, the default is to return true and render. The function accepts a
 *    list of feature strings, a list of permission strings, and a boolean value if the user
 *    is an administrator of their organization.
 *    filter: (feature, permissions, isOrgAdmin) => isOrgAdmin,
 *
 *   `routing` is literally react-router-dom Route properties with the exclusion of children. So
 *   `path`, `fullPath`, `strict`, `sensitive`, `component`, and `render` are all valid. Additional properties
 *  The `fullPath` property will redirect the link to use that full path instead of concatenating it in it's current structure. Example is wanting to show 'marketplace' even though the name says 'My Services'. This was created so we didn't have to update existing urls to change the navigation look.
 *   will not be spread to the underlying component (this can be changed if the need arises).
 *   routing: {
 *     path: '/somewhere'
 *     fullPath: '/customers/maintenance-and-repair/new-route'
 *   },
 *
 *   `navigation` accepts only one property, the 'to' path used by react-router-dom Link component.
 *   This implementation only allows a string values for to. Link's `replace` and `innerRef` are not
 *   supported and additional props are not spread to the underlying Link.
 *   navigation: {
 *     to: '/somewhere'
 *   },
 *
 *   Allows for nested navigation. A subroute's parent `navigation.to` and `routing.path` will be
 *   appended to it's own `navigation.to` and `routing.path` properties. So you need only supply
 *   the next part of the path.
 *   subroutes: []
 * };
 */

const HomeRoute = {
  id: 'home',
  routing: {
    path: '/',
    exact: true,
    component: Home,
  },
};

const AvailableServicesRoute = {
  id: 'available-services',
  homepage: true,
  routing: {
    path: '/services',
    exact: true,
    component: AvailableServices,
  },
};

const ShopVehiclesRoutes = {
  id: 'shop-vehicles',
  navigation: {
    to: '/shop-vehicles',
  },
  routing: {
    path: '/shop-vehicles',
    exact: true,
    component: Placeholder,
  },
  subroutes: [
    {
      id: 'marketplace-shop-stock-vehicles-landing',
      filter: hasFeature(Features.SHOP_STOCK_VEHICLES.INDEX),
      routing: {
        path: '/shop-stock-vehicles/landing',
        fullPath: '/marketplace/shop-stock-vehicles/landing',
        exact: true,
        component: StockLanding,
      },
      navigation: {
        to: '/shop-stock-vehicles/landing',
      },
      details:
        'View our dealer network of vehicles for your immediate or short-term needs',
    },
    {
      id: 'shop-stock-inventory',
      filter: hasFeature(Features.SHOP_STOCK_VEHICLES.INDEX),
      homepage: true,
      routing: {
        path: '/shop-stock-vehicles',
        fullPath: '/marketplace/shop-stock-vehicles',
        exact: true,
        component: BrowseStock,
      },
    },
    {
      id: 'stock-request-quote',
      filter: anyPass(
        hasFeature(Features.SHOP_STOCK_VEHICLES.PURCHASE),
        hasFeature(Features.SHOP_FACTORY_VEHICLES.PURCHASE),
        hasFeature(Features.SHOP_POOL_VEHICLES.PURCHASE)
      ),
      routing: {
        path: '/shop-stock-vehicles/request-quote/:vinNo',
        fullPath: '/marketplace/shop-stock-vehicles/request-quote/:vinNo',
        exact: true,
        component: RequestQuote,
      },
    },
    {
      id: 'stock-search-detail',
      filter: either(
        hasFeature(Features.SHOP_STOCK_VEHICLES.INDEX),
        hasFeature(Features.SHOP_VEHICLES.VIEW_ORDERS)
      ),
      routing: {
        path: '/shop-stock-vehicles/:vinNo',
        fullPath: '/marketplace/shop-stock-vehicles/:vinNo',
        exact: true,
        component: StockSearchDetail,
      },
    },
    {
      id: 'shop-pool-vehicles',
      filter: hasFeature(Features.SHOP_POOL_VEHICLES.INDEX),
      homepage: true,
      routing: {
        path: '/shop-pool-vehicles',
        exact: true,
        render: () => <SelectorsList type="pool" />,
      },
      navigation: {
        to: '/shop-pool-vehicles',
      },
      details:
        'Source trade-specific vehicles pre-built for your business needs',
    },
    {
      id: 'shop-factory-vehicles',
      filter: hasFeature(Features.SHOP_FACTORY_VEHICLES.INDEX),
      homepage: true,
      routing: {
        path: '/vehicle-acquisition',
        fullPath: '/marketplace/vehicle-acquisition',
        exact: true,
        component: SelectorsList,
      },
      navigation: {
        to: '/vehicle-acquisition',
      },
      details:
        'Plan future replacements with our lowest cost, standard vehicle offering',
    },
    {
      id: 'single-selector',
      filter: anyPass(
        hasFeature(Features.SHOP_FACTORY_VEHICLES.INDEX),
        hasFeature(Features.SHOP_POOL_VEHICLES.INDEX),
        hasFeature(Features.SHOP_VEHICLES.VIEW_ORDERS)
      ),
      routing: {
        path: '/vehicle-acquisition/:savedVehicleId',
        fullPath: '/marketplace/vehicle-acquisition/:savedVehicleId',
        exact: true,
        component: SingleSelector,
      },
    },
    {
      id: 'vehicle-acquisition-request-quote',
      filter: either(
        hasFeature(Features.SHOP_FACTORY_VEHICLES.PURCHASE),
        hasFeature(Features.SHOP_POOL_VEHICLES.PURCHASE)
      ),
      routing: {
        path: '/vehicle-acquisition/request-quote/:savedVehicleId',
        fullPath:
          '/marketplace/vehicle-acquisition/request-quote/:savedVehicleId',
        exact: true,
        component: RequestQuote,
      },
    },
  ],
};

const MyServicesRoutes = {
  id: 'my-services',
  navigation: {
    to: '/my-services',
  },
  routing: {
    path: '/my-services',
    exact: true,
    component: Placeholder,
  },
  subroutes: [
    {
      id: 'fxg',
      filter: hasFeature(Features.SHOP_VEHICLES.PURCHASE),
      routing: {
        path: '/fxg-shop',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/fxg-shop',
      },
      subroutes: [
        {
          id: 'marketplace-shop-vehicles',
          filter: hasFeature(Features.SHOP_VEHICLES.PURCHASE),
          routing: {
            path: '/shop-vehicles',
            fullPath: '/marketplace/shop-vehicles',
            exact: true,
            component: ShopVehiclesContainer,
          },
          navigation: {
            to: '/shop-vehicles',
          },
        },
      ],
    },
    {
      id: 'fuel-management',
      filter: hasFeature(Features.FUEL_MANAGEMENT.INDEX),
      routing: {
        path: '/fuel-management',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/fuel-management',
      },
      subroutes: [
        {
          id: 'manage-fuel-cards',
          filter: hasFeature(Features.FUEL_MANAGEMENT.INDEX),
          routing: {
            path: '/manage-fuel-cards',
            fullPath: '/customer/services/fuel-management/manage-fuel-cards',
            exact: true,
            component: ManageFuelCards,
          },
          navigation: {
            to: '/manage-fuel-cards',
          },
        },
        {
          id: 'replace-fuel-card',
          filter: hasFeature(Features.FUEL_MANAGEMENT.INDEX),
          routing: {
            fullPath:
              '/customer/services/fuel-management/replace-fuel-card/:fuelCardId?',
            exact: true,
            component: FuelCardReplacement,
          },
          navigation: {
            fullTo: '/customer/services/fuel-management/replace-fuel-card',
          },
        },
        {
          id: 'fuel-card-transactions',
          filter: hasFeature(Features.FUEL_MANAGEMENT.INDEX),
          homepage: true,
          routing: {
            path: '/fuel-card-transactions',
            fullPath:
              '/customer/services/fuel-management/fuel-card-transactions',
            exact: true,
            component: FuelCardTransactions,
          },
          navigation: {
            to: '/fuel-card-transactions',
            fullTo: `/customer/services/fuel-management/fuel-card-transactions?specificRange~gte=${Date.now() -
              1000 * 60 * 60 * 24 * 15}`, // from 15 days ago
          },
        },
        {
          id: 'fuel-pin-transactions',
          filter: hasFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PINS),
          homepage: true,
          routing: {
            path: '/fuel-pin-transactions',
            fullPath:
              '/customer/services/fuel-management/fuel-pin-transactions',
            exact: true,
            component: FuelPinTransactions,
          },
          navigation: {
            to: `/fuel-pin-transactions`,
            fullTo: `/customer/services/fuel-management/fuel-pin-transactions?specificRange~gte=${Date.now() -
              1000 * 60 * 60 * 24 * 15}`, // from 15 days ago
          },
        },
        {
          id: 'asset-transactions',
          filter: hasFeature(Features.FUEL_MANAGEMENT.INDEX),
          routing: {
            path: '/asset-transaction/:assetId?',
            fullPath:
              '/customer/services/fuel-management/asset-transactions/:assetId?',
            exact: true,
            component: FuelAssetTransactions,
          },
        },
        {
          id: 'fuel-card-preferences',
          filter: hasFeature(Features.FUEL_MANAGEMENT.INDEX),
          routing: {
            path: '/fuel-card-preferences',
            fullPath:
              '/customer/services/fuel-management/fuel-card-preferences',
            exact: true,
            component: FuelCardPreferences,
          },
          navigation: {
            to: '/fuel-card-preferences',
          },
        },
        {
          id: 'fuel-management-preferences',
          filter: either(
            hasFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PINS),
            hasFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES)
          ),
          routing: {
            path: '/preferences',
            exact: true,
            component: FuelManagementPreferences,
          },
          navigation: {
            to: '/preferences',
          },
        },
        {
          id: 'fuel-new-asset-group',
          filter: hasFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES),
          routing: {
            path: '/preferences/new-asset-group',
            exact: true,
            component: FuelNewAssetGroup,
          },
        },
        {
          id: 'new-fuel-profile',
          filter: hasFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES),
          routing: {
            path: '/preferences/new-fuel-profile',
            exact: true,
            component: NewFuelProfile,
          },
        },
      ],
    },
    {
      id: 'maintenance-and-repair',
      filter: hasFeature(Features.SERVICE_MAINT.INDEX),
      routing: {
        path: '/maintenance-and-repair',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/maintenance-and-repair',
      },
      subroutes: [
        {
          id: 'customers-service-request',
          filter: hasFeature(Features.CUSTOMERS.REQUESTS),
          homepage: true,
          routing: {
            path: '/request-management',
            fullPath: '/customer/request-management',
            exact: true,
            component: BrowseServiceRequests,
          },
        },
        {
          id: 'maintenance-history',
          filter: hasFeature(Features.SERVICE_MAINT.INDEX),
          routing: {
            path: '/history',
            fullPath: '/customer/services/maintenance-and-repair/history',
            exact: true,
            component: BrowseMaintenanceRepairHistory,
          },
          navigation: {
            to: '/history',
            fullTo: `/customer/services/maintenance-and-repair/history?requestedAt~gte=${Date.now() -
              1000 * 60 * 60 * 24 * 30}`, // from 30 days ago
          },
        },
        {
          id: 'customers-service-request-update',
          /** @todo What FEATURE am I looking for?  */
          filter: hasAssetBasedService,
          routing: {
            path: '/request-management/:id?',
            fullPath: '/customer/request-management/:id?',
            component: ViewUpdateRequest,
          },
        },
        {
          id: 'vendor-locator',
          filter: hasFeature(Features.SERVICE_MAINT.VENDOR_LOCATOR),
          routing: {
            path: '/vendor-locator',
            fullPath:
              '/customer/services/maintenance-and-repair/vendor-locator',
            exact: true,
            component: VendorLocator,
          },
          navigation: {
            to: '/vendor-locator',
          },
        },
        {
          id: 'pm-tracker',
          filter: hasFeature(Features.SERVICE_MAINT.INDEX),
          routing: {
            path: '/pm-tracker',
            fullPath: '/customer/services/maintenance-and-repair/pm-tracker',
            exact: true,
            component: BrowsePmNotification,
          },
          navigation: {
            to: '/pm-tracker',
          },
        },
        {
          id: 'mr-preferences',
          filter: hasFeature(Features.SERVICE_MAINT.INDEX),
          routing: {
            path: '/preferences',
            fullPath: '/customer/services/maintenance-and-repair/preferences',
            exact: true,
            component: MRPreferences,
          },
          navigation: {
            to: '/preferences',
          },
        },
        {
          id: 'history',
          /** @todo is this the correct feature here */
          filter: hasAssetBasedService,
          routing: {
            path: '/history/:assetNo?',
            fullPath:
              '/customer/services/maintenance-and-repair/history/:assetNo?',
            exact: true,
            component: BrowseMaintenanceRepairHistory,
          },
        },
      ],
    },
    {
      id: 'telematics-geotab',
      filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
      routing: {
        path: '/telematics/geotab',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: 'telematics/geotab',
      },
      subroutes: [
        {
          id: 'telematics-my-geotab',
          filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
          routing: {
            path: '/my-geotab',
            exact: true,
            component: MyGeotabRedirect,
            fullPath: '/telematics/geotab/my-geotab',
          },
          navigation: {
            to: '/my-geotab',
          },
        },
        {
          id: 'telematics-ordering',
          filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
          routing: {
            path: '/order',
            exact: true,
            component: HardwareOrdering,
            fullPath: '/telematics/geotab/order',
          },
          navigation: {
            to: '/order',
          },
        },
        {
          id: 'telematics-order-view',
          filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
          routing: {
            path: '/orders',
            exact: true,
            component: BrowseHardwareOrders,
            fullPath: '/telematics/geotab/orders',
          },
          navigation: {
            to: '/orders',
          },
        },
      ],
    },
    {
      id: 'driver-training',
      filter: hasFeature(Features.DRIVER_TRAINING.MANAGEMENT),
      routing: {
        path: '/driver-training',
        exact: true,
        component: DriverTrainingContainer,
      },
      navigation: {
        to: '/driver-training',
      },
      subroutes: [
        {
          id: 'training-management',
          filter: hasFeature(Features.DRIVER_TRAINING.MANAGEMENT),
          routing: {
            path: '/training-management',
            fullPath: '/customer/services/driver-training/training-management',
            exact: true,
            component: TrainingSignupManagement,
          },
          navigation: {
            to: '/training-management',
          },
        },
        {
          id: 'training-assignment',
          filter: hasFeature(Features.DRIVER_TRAINING.MANAGEMENT),
          routing: {
            path: '/training-assignment',
            fullPath: '/customer/services/driver-training/training-assignment',
            exact: true,
            component: BrowseTrainingAssignment,
          },
          navigation: {
            to: '/training-assignment',
          },
        },
        {
          id: 'driver-training-assignments',
          filter: both(
            hasFeature(Features.DRIVER_TRAINING.TAKE_TRAINING),
            isNotOrgAdmin
          ),
          homepage: true,
          routing: {
            path: '/driver-training-assignments',
            fullPath:
              '/customer/services/driver-training/driver-training-assignments',
            exact: true,
            component: DriverTrainingAssignments,
          },
          navigation: {
            to: '/driver-training-assignments',
          },
        },
        {
          id: 'driver-training-purchase',
          filter: hasFeature(Features.DRIVER_TRAINING.PURCHASE),
          routing: {
            path: '/driver-training',
            fullPath: '/marketplace/driver-training',
            exact: true,
            component: DriverTrainingContainer,
          },
          navigation: {
            to: '/driver-training',
          },
        },
      ],
    },
    {
      id: 'supplier',
      // filter: hasFeature(Features.SUPPLIERS.INDEX),
      routing: {
        path: '/admin',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/admin',
      },
      subroutes: [
        {
          id: 'supplier-services',
          filter: hasFeature(Features.SUPPLIERS.INDEX),
          routing: {
            path: '/services',
            fullPath: '/supplier/services',
            exact: true,
            component: SupplierServices,
          },
          navigation: {
            to: '/services',
          },
        },
        {
          id: 'dealer-model-org-admin',
          filter: hasFeature(Features.DEALERS.ADMIN),
          routing: {
            path: '/dealer-model-admin',
            fullPath: '/supplier/dealer-model-admin',
            exact: true,
            component: DealerModelAdmin,
          },
          navigation: {
            to: '/dealer-model-admin',
          },
        },
      ],
    },
    {
      id: 'telematics',
      filter: hasFeature(Features.TELEMETRY.INDEX),
      routing: {
        path: '/telematics',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/telematics',
      },
      subroutes: [
        {
          id: 'telematics-dashboard',
          filter: hasFeature(Features.TELEMETRY.DASHBOARD),
          homepage: true,
          routing: {
            path: '/dashboard',
            fullPath: '/customer/services/telematics/dashboard',
            exact: true,
            component: BrowseTelematics,
          },
          navigation: {
            to: '/dashboard',
          },
        },
      ],
    },
    {
      id: 'remarketing',
      filter: hasFeature(Features.REMARKETING),
      routing: {
        path: '/remarketing',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/remarketing',
      },
      subroutes: [
        {
          id: 'remarketing-buydirect',
          filter: hasFeature(Features.BUYDIRECT),
          homepage: true,
          routing: {
            path: '/buydirect',
            exact: true,
            component: BuyDirectQuoteRequest,
          },
          navigation: {
            to: '/buydirect',
          },
        },
        {
          id: 'remarketing-buydirect',
          filter: hasFeature(Features.BUYDIRECT),
          homepage: false,
          routing: {
            path: '/buydirect/:assetId',
            exact: true,
            component: BuyDirectQuoteRequest,
          },
        },
        {
          id: 'remarketing-plb',
          filter: hasFeature(Features.PLB),
          homepage: true,
          routing: {
            path: '/purchase-lease-back',
            exact: true,
            component: PLBQuoteRequest,
          },
          navigation: {
            to: '/purchase-lease-back',
          },
        },
        {
          id: 'remarketing-plb',
          filter: hasFeature(Features.BUYDIRECT),
          homepage: false,
          routing: {
            path: '/purchase-lease-back/:assetId',
            exact: true,
            component: PLBQuoteRequest,
          },
        },
        {
          id: 'remarketing-requests',
          filter: hasFeature(Features.REMARKETING),
          routing: {
            path: '/requests',
            exact: true,
            component: RemarketingRequests,
          },
          navigation: {
            to: '/requests',
          },
        },
        {
          id: 'request-confirmation-buydirect',
          filter: hasFeature(Features.BUYDIRECT),
          routing: {
            path: '/request-confirmation-buydirect/:workflowId',
            exact: true,
            component: BuyDirectRequestReceipt,
          },
        },
        {
          id: 'request-confirmation-plb',
          filter: hasFeature(Features.PLB),
          routing: {
            path: '/request-confirmation-plb/:workflowId',
            exact: true,
            component: PLBRequestReceipt,
          },
        },
        {
          id: 'request-confirmation-multi-asset-plb',
          filter: hasFeature(Features.PLB),
          routing: {
            path: '/request-confirmation-plb/',
            exact: true,
            component: PLBRequestReceipt,
          },
        },
        {
          id: 'remarketing-pending-requests',
          filter: hasFeature(Features.PLB), // todo add BuyDirect
          routing: {
            path: '/pending-requests/',
            exact: true,
            component: PendingRequests,
          },
        },
        {
          id: 'buydirect-status',
          filter: hasFeature(Features.BUYDIRECT),
          routing: {
            path: '/buydirect/status/:workflowId',
            exact: true,
            component: BuyDirectStatus,
          },
        },
        {
          id: 'plb-status',
          filter: hasFeature(Features.PLB),
          routing: {
            path: '/plb/status/:workflowId',
            exact: true,
            component: PLBStatus,
          },
        },
      ],
    },
    // {
    //   id: 'loss-reporting',
    //   // filter: hasFeature(Features.MARKETPLACE.INDEX),
    //   routing: {
    //     path: '/loss-reporting/browse',
    //     exact: true,
    //     component: BrowseLossReports,
    //   },
    //   navigation: {
    //     to: '/loss-reporting/browse',
    //   },
    // },
    // {
    //   id: 'create-loss-reporting',
    //   // filter: hasFeature(Features.MARKETPLACE.INDEX),
    //   routing: {
    //     path: '/loss-reporting/new',
    //     exact: true,
    //     component: CreateLossReport,
    //   },
    // },
    // {
    //   id: 'update-loss-reporting',
    //   // filter: hasFeature(Features.MARKETPLACE.INDEX),
    //   routing: {
    //     path: '/loss-reporting/:lossReportId',
    //     exact: true,
    //     component: UpdateLossReport,
    //   },
    // },
  ],
};

const TelematicsOrdering = {
  id: 'telematics-geotab',
  filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
  routing: {
    path: '/telematics/geotab',
    exact: true,
    component: Placeholder,
  },
  subroutes: [
    {
      id: 'hardware-ordering',
      filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
      routing: {
        path: '/order',
        exact: true,
        component: HardwareOrdering,
      },
    },
    {
      id: 'hardware-orders',
      filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
      routing: {
        path: '/orders',
        exact: true,
        component: BrowseHardwareOrders,
      },
    },
    {
      id: 'hardware-confirmation',
      filter: hasFeature(Features.TELEMATICS_GEOTAB.INDEX),
      routing: {
        path: '/order/:id',
        exact: true,
        component: HardwareStatus,
      },
    },
  ],
  // navigation: {
  //   to: 'telematics-ordering',
  // },
};

const MyOrganizationRoutes = {
  id: 'my-organization',
  navigation: {
    to: '/my-organization',
  },
  routing: {
    path: '/my-organization',
    fullPath: '/my-organization',
    exact: true,
    component: Placeholder,
  },
  subroutes: [
    {
      id: 'account-setup',
      filter: hasFeature(Features.SERVICE_MAINT.INDEX),
      routing: {
        path: '/account-setup',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/account-setup',
      },
      subroutes: [
        {
          id: 'customers-setup',
          filter: hasFeature(Features.CUSTOMERS.ORG_SETUP),
          routing: {
            path: '/organization-setup',
            fullPath: '/customer/organization-setup',
            exact: true,
            component: OrganizationPage,
          },
          navigation: {
            to: '/organization-setup',
          },
        },
        {
          id: 'customers-location',
          filter: hasFeature(Features.CUSTOMERS.LOCATIONS),
          routing: {
            path: '/locations',
            fullPath: '/customer/locations',
            component: OrgLocationHierarchy,
          },
          navigation: {
            to: '/locations',
          },
        },
        {
          id: 'locations-overview',
          filter: hasFeature(Features.CUSTOMERS.LOCATIONS),
          routing: {
            path: '/location-overview',
            fullPath: '/customer/location-overview',
            component: LocationOverview,
          },
          navigation: {
            to: '/location-overview',
          },
        },
        {
          id: 'customers-users',
          filter: both(
            hasFeature(Features.CUSTOMERS.USERS),
            doesNotHaveFeature(Features.SHOP_VEHICLES.PURCHASE)
          ),
          routing: {
            path: '/users',
            fullPath: '/customer/users',
            exact: true,
            component: UsersManagement,
          },
          navigation: {
            to: '/users',
          },
        },
        // The following 2 routes are identical but render different components based on the following condition:
        // If org is enrolled in driver training, use the old page. Otherwise, use the new page.
        {
          id: 'customers-roles',
          filter: both(
            hasFeature(Features.CUSTOMERS.ROLES),
            hasFeature(Features.DRIVER_TRAINING.INDEX)
          ),
          routing: {
            path: '/roles',
            fullPath: '/customer/roles',
            exact: true,
            component: RolesCrud,
          },
          navigation: {
            to: '/roles',
          },
        },
        {
          id: 'customers-roles',
          filter: both(
            hasFeature(Features.CUSTOMERS.ROLES),
            doesNotHaveFeature(Features.DRIVER_TRAINING.INDEX)
          ),
          routing: {
            path: '/roles',
            fullPath: '/customer/roles',
            exact: true,
            component: ManageRoles,
          },
          navigation: {
            to: '/roles',
          },
        },
      ],
    },
    {
      id: 'payment-billing',
      routing: {
        path: '/payment-billing',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/payment-billing',
      },
      subroutes: [
        {
          id: 'customers-payment-history',
          filter: hasFeature(Features.CUSTOMERS.PAYMENT_HISTORY),
          routing: {
            path: '/payment-history',
            fullPath: '/customer/payment-history',
            exact: true,
            component: BrowsePaymentHistory,
          },
          navigation: {
            to: '/payment-history',
          },
        },
        {
          id: 'payment-options',
          filter: hasFeature(Features.CUSTOMERS.PAYMENT_OPTIONS),
          routing: {
            path: '/payment-options',
            fullPath: '/customer/payment-options',
            exact: true,
            component: PaymentOptions,
          },
          navigation: {
            to: '/payment-options',
          },
        },
        {
          id: 'customers-billing-history',
          filter: hasFeature(Features.CUSTOMERS.CONSOLIDATED_BILLING),
          homepage: true,
          routing: {
            path: '/billing-history/index',
            fullPath: '/customer/billing-history/index',
            exact: true,
            component: ConsolidatedBillingIndex,
          },
          navigation: {
            to: '/billing-history/index',
            fullTo: `/customer/billing-history/index`,
          },
        },
        {
          id: 'browse-customers-billing-history',
          filter: hasFeature(Features.CUSTOMERS.CONSOLIDATED_BILLING),
          homepage: false,
          routing: {
            path: '/billing-history',
            fullPath: `/customer/billing-history`,
            exact: true,
            component: ConsolidatedBilling,
          },
        },
      ],
    },
    {
      id: 'asset',
      filter: hasFeature(Features.ASSETS.INDEX),
      routing: {
        path: '/asset',
        exact: true,
        component: Placeholder,
      },
      navigation: {
        to: '/asset',
      },
      subroutes: [
        {
          id: 'parent-assets',
          filter: hasFeature(Features.PARENT_ORG.INDEX),
          routing: {
            path: '/assets',
            fullPath: '/parent/assets',
            exact: true,
            component: ParentAssets,
          },
          navigation: {
            to: '/assets',
          },
        },
        {
          id: 'assets',
          filter: hasFeature(Features.ASSETS.INDEX),
          homepage: true,
          routing: {
            path: '/assets',
            fullPath: '/asset/assets',
            exact: true,
            component: BrowseFleetAssets,
          },
          navigation: {
            to: '/assets',
          },
        },
        {
          id: 'add-asset',
          filter: hasFeature(Features.ASSETS.CREATE),
          routing: {
            path: '/add-asset',
            fullPath: '/asset/add-asset',
            exact: true,
            component: AssetAddAssetSelector,
          },
        },
        {
          id: 'asset-ctas',
          filter: hasFeature(Features.CUSTOMERS.CALL_TO_ACTION),
          routing: {
            path: '/asset-ctas',
            fullPath: '/asset/asset-ctas',
            exact: true,
            component: AssetCTAs,
          },
        },
        {
          id: 'view-orders',
          filter: hasFeature(Features.SHOP_VEHICLES.VIEW_ORDERS),
          homepage: true,
          routing: {
            path: '/view-orders',
            fullPath: '/asset/view-orders',
            exact: true,
            component: ShopVehiclesContainer,
          },
          navigation: {
            to: '/view-orders',
          },
        },
      ],
    },
  ],
};

const ParentOrgRoutes = {
  id: 'parent-org',
  filter: hasFeature(Features.PARENT_ORG.INDEX),
  homepage: true,
  routing: {
    path: '/parent',
    exact: true,
    component: ParentOrgManagement,
  },
  navigation: {
    to: '/parent',
  },
  subroutes: [
    {
      id: 'parent-individual-asset',
      routing: {
        path: '/assets/:assetNo/:childOrgId',
        exact: true,
        component: AssetSelector,
      },
    },
    {
      id: 'parent-view-orders',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/orders',
        exact: true,
        component: ParentListOrders,
      },
    },
    {
      id: 'parent-view-fuel-transactions',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/fuel/transactions',
        exact: true,
        component: ParentFuelCardsTransactions,
      },
    },
    {
      id: 'parent-view-fuel-cards',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/fuel/cards',
        exact: true,
        component: ParentFuelCards,
      },
    },
    {
      id: 'parent-view-fuel-asset-transactions',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path:
          '/:childOrgId/fuel-management/asset-transactions/:childOrgAssetId',
        exact: true,
        component: ParentFuelTransactions,
      },
    },
    {
      id: 'parent-billing-history-index',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/billing-history/index',
        exact: true,
        component: ConsolidatedBillingIndex,
      },
    },
    {
      id: 'parent-billing-history',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/billing-history',
        exact: true,
        component: ParentConsolidatedBilling,
      },
    },
    {
      id: 'parent-maint-repair',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/services/maintenance-and-repair/history/:assetNo',
        exact: true,
        component: ParentMaintRepairHistory,
      },
    },
    {
      id: 'parent-customers-service-request',
      filter: hasFeature(Features.PARENT_ORG.INDEX),
      routing: {
        path: '/:childOrgId/requests',
        exact: true,
        component: ParentRequests,
      },
    },
  ],
};

const MyAccountRoute = {
  id: 'my-account',
  routing: {
    path: '/my-account',
    exact: true,
    component: MyAccount,
  },
};

const CreditDecisionRoute = {
  id: 'credit-decision',
  filter: hasFeature(Features.SUPPLIERS.INDEX),
  routing: {
    path: '/services/credit-decision',
    exact: true,
    component: CreditDecision,
  },
};

const ManageOrdersRoute = {
  id: 'manage-orders',
  routing: {
    path: '/services/manage-orders',
    exact: true,
    component: ManageOrders,
  },
};

const PricingUpdatesRoute = {
  id: 'pricing-updates',
  routing: {
    path: '/services/pricing-updates',
    exact: true,
    component: PricingUpdates,
  },
};

const ComponentsPageRoute = {
  id: 'components-page',
  routing: {
    path: '/components-page',
    exact: false,
    component: ComponentsPage,
  },
};

const InvididualAssetPageRoute = {
  id: 'individual-asset',
  routing: {
    path: '/assets/:assetNo',
    exact: true,
    component: AssetSelector,
  },
};

const ContactUsRoute = {
  id: 'contact-us',
  routing: {
    path: '/contact-us',
    exact: false,
    component: ContactUs,
  },
};

const NotFoundRoute = {
  id: 'not-found',
  routing: {
    path: null,
    exact: false,
    component: NotFound,
  },
};

const ThemeRoute = {
  id: 'theme-route',
  routing: {
    path: '/theme',
    exact: false,
    component: ThemeExample,
  },
};

const DealerPaymentEstRoute = {
  id: 'payment-estimator',
  filter: hasFeature(Features.DEALERS.PAYMENT_ESTIMATOR),
  navigation: {
    to: '/payment-estimator',
    isTopLink: true,
  },
  routing: {
    path: '/payment-estimator',
    exact: true,
    component: PaymentEstimator,
  },
};

const DealerPaymentEstimatorResumeRoute = {
  id: 'payment-estimator-resume',
  filter: hasFeature(Features.DEALERS.PAYMENT_ESTIMATOR),
  routing: {
    path: '/payment-estimator/:workflowId',
    exact: true,
    component: ResumeEstimator,
  },
};

const DealerEnrollmentRoute = {
  id: 'start-enrollment',
  filter: hasFeature(Features.DEALERS.START_ENROLLMENT),
  navigation: {
    to: '/start-enrollment',
    isTopLink: true,
  },
  routing: {
    path: '/start-enrollment',
    exact: false,
    component: OrgSetupSinglePage,
  },
};

const DealerQuoteRequest = {
  id: 'dealer-quote-request',
  filter: hasFeature(Features.DEALERS.PAYMENT_ESTIMATOR),
  routing: {
    path: '/quote-request/:workflowId',
    exact: true,
    component: PaymentEstimatorQuoteRequest,
  },
};

const RouteSpecs = [
  {
    id: 'dmca',
    routing: {
      path: '/dmca',
      exact: true,
      component: DMCA,
    },
  },
  {
    id: 'terms-of-use',
    routing: {
      path: '/terms-of-use',
      exact: true,
      component: TermsOfUse,
    },
  },
  {
    id: 'privacy-policy',
    routing: {
      path: '/privacy-policy',
      exact: true,
      component: PrivacyPolicy,
    },
  },
  {
    id: 'telematics-terms-and-conditions',
    routing: {
      path: '/telematics-terms-and-conditions',
      exact: true,
      component: TelematicsTermsAndConditions,
    },
  },
  {
    id: 'enroll-next-steps',
    filter: hasFeature(Features.DEALERS.START_ENROLLMENT),
    routing: {
      path: '/next-steps',
      exact: false,
      component: DealerNextSteps,
    },
  },
  AvailableServicesRoute,
  ShopVehiclesRoutes,
  MyServicesRoutes,
  MyOrganizationRoutes,
  ParentOrgRoutes,
  ThemeRoute,
  HomeRoute,
  CreditDecisionRoute,
  ManageOrdersRoute,
  PricingUpdatesRoute,
  TelematicsOrdering,
  DealerPaymentEstRoute,
  DealerPaymentEstimatorResumeRoute,
  DealerEnrollmentRoute,
  DealerQuoteRequest,
  InvididualAssetPageRoute,
  MyAccountRoute,
  ContactUsRoute,
  ComponentsPageRoute,
  NotFoundRoute,
];

export const generateNavigation = generateNavigationRoutesWithSpecs.bind(
  undefined,
  RouteSpecs
);

export const generateRouting = generateRoutingWithSpecs.bind(
  undefined,
  RouteSpecs
);
